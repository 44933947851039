import { API } from '@src/constants/api'
import { FilterByInterface } from '@src/interfaces/data'
import {
  EmployeeTotalCompensationStatsInterface,
  EmployeeTotalCompensationTimeSeriesInterface,
} from '@src/interfaces/totalCompensation'
import { useFetch } from '@src/utils/reactQuery'
import { filterSortPageIntoQuery } from '@src/utils/table'

export const useGetEmployeeTotalCompensationTimeSeries = (
  employeeId: number | string,
  filtersParams: FilterByInterface[],
) =>
  useFetch<EmployeeTotalCompensationTimeSeriesInterface[]>(
    `${API.TOTAL_COMPENSATION}/${employeeId}/timeSeries`,
    'v1',
    {
      params: filterSortPageIntoQuery(undefined, filtersParams),
    },
  )

export const useGetEmployeeTotalCompensationStats = (
  employeeId: string | number,
  filtersParams: FilterByInterface[],
) =>
  useFetch<EmployeeTotalCompensationStatsInterface>(
    `${API.TOTAL_COMPENSATION}/${employeeId}/stats`,
    'v1',
    {
      params: filterSortPageIntoQuery(undefined, filtersParams),
    },
  )

import React from 'react'
import { TwoColumnsLayout } from '@src/pages/EmployeeProfile/Layout/common/TwoColumnsLayout'
import { CompensationParams } from '@src/pages/EmployeeProfile/Layout/common/types'
import { OverTimeCompensation } from './OverTimeCompensation'
import { TotalCompensation } from './TotalCompensation'

type Props = {
  compensationParams: CompensationParams
  id: number
}

export const CompensationWidget = ({ compensationParams, id }: Props) => {
  return (
    <TwoColumnsLayout
      left={<TotalCompensation compensationParams={compensationParams} id={id} />}
      right={<OverTimeCompensation compensationParams={compensationParams} id={id} />}
      rightFlex={3}
    />
  )
}

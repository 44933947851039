import React from 'react'
import { Flex, Icon, HStack, Progress, Text, Token, Widget } from '@revolut/ui-kit'
import { useGetEmployeeTotalCompensationStats } from '@src/api/totalCompensation'
import { CurrencySelect } from '@components/CurrencySelect/CurrencySelect'
import Tooltip from '@components/Tooltip/Tooltip'
import { CompensationParams } from '@src/pages/EmployeeProfile/Layout/common/types'
import { formatMoney } from '@src/utils/format'
import { PieChart } from '../Charts/PieChart'

type Props = {
  compensationParams: CompensationParams
  id: number
}

export const TotalCompensation = ({ compensationParams, id }: Props) => {
  const { currency, setCurrency } = compensationParams
  const filters = currency
    ? [
        {
          filters: [{ id: `${currency}`, name: `${currency}` }],
          columnName: 'currency_iso_code',
        },
      ]
    : []

  const { data, isLoading } = useGetEmployeeTotalCompensationStats(id, filters)

  if (!isLoading && !data) {
    return null
  }

  const pieChartData = [
    {
      name: 'Base salary',
      value: data ? Number(data.current_year_base_salary) : 0,
    },
    {
      name: 'Vested equity',
      value: data ? Number(data.current_year_vested_equity) : 0,
    },
    {
      name: 'Cash bonus',
      value: data ? Number(data.current_year_cash_bonus) : 0,
    },
  ]

  const progressValue = data
    ? Number(data.total_vested_equity) / Number(data.total_equity)
    : 0

  return (
    <Flex
      alignItems="stretch"
      flexDirection="column"
      height="55vh"
      gap="s-16"
      data-testid="total_compensation_section"
    >
      <Widget flex={1} p="s-16">
        <Flex alignItems="stretch" flexDirection="column" height="100%">
          <Flex alignItems="center" gap="s-4">
            <Text variant="emphasis2">Total compensation</Text>
            <Tooltip
              placement="bottom"
              text="Current compensation includes your base salary, vested equity, and cash bonus."
            >
              <Icon name="InfoOutline" size={16} />
            </Tooltip>
          </Flex>

          <CurrencySelect
            label=""
            onCurrencyChange={({ iso_code }) => setCurrency(iso_code)}
            value={data?.currency.iso_code}
          />

          <PieChart
            currency={data?.currency.iso_code}
            data={pieChartData}
            isLoading={isLoading}
            total={data ? Number(data.current_year_total) : undefined}
          />
        </Flex>
      </Widget>

      {data && !!Number(data.total_equity) && (
        <Widget p="s-16">
          <HStack gap="s-4" mb="s-16">
            <Text variant="emphasis2">All time equity</Text>
            <Tooltip
              placement="bottom"
              text="The total value of your equity, including both vested and unvested shares."
            >
              <Icon name="InfoOutline" size={16} />
            </Tooltip>
          </HStack>

          <Flex alignItems="center" justifyContent="space-between" mb="s-8">
            <Text color={Token.color.accent} variant="body2">
              Vested
            </Text>
            <Text variant="heading3">
              {formatMoney(Number(data.total_equity), data.currency.iso_code)}
            </Text>
            <Text color={Token.color.greyTone50} variant="body2">
              Unvested
            </Text>
          </Flex>
          <Progress value={progressValue}>
            <Progress.Label color={Token.color.accent}>
              {formatMoney(Number(data.total_vested_equity), data.currency.iso_code)}
            </Progress.Label>
            <Progress.Label>
              {formatMoney(Number(data.total_unvested_equity), data.currency.iso_code)}
            </Progress.Label>
          </Progress>
        </Widget>
      )}
    </Flex>
  )
}
